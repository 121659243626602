import axios from "axios";
import store from "@/store";
import i18n from "@/i18n";
import JwtService from "@/common/jwt.service";
import { Message, Loading, MessageBox } from "element-ui";

const interceptor = axios.create();

let loadingInstance;

interceptor.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (localStorage.id_token) {
      config.headers.Authorization = "Bearer " + localStorage.id_token;
    }

    config.headers["Accept-Language"] = i18n.locale;
    config.headers["Mock"] = "toefl";

    loadingInstance = Loading.service({
      lock: true,
      background: "transparent",
      spinner: "el-icon-loading"
    });

    return config;
  },
  function(error) {
    // Do something with request error
    loadingInstance.close();
    return Promise.reject(error);
  }
);

interceptor.interceptors.response.use(
  function(response) {
    loadingInstance.close();
    
    return response.data;
  },
  function(error) {
    if (error && error.stack.indexOf("timeout") > -1) {
      MessageBox({
        title: i18n.t("message.notice"),
        message: `
          ${i18n.t("message.chunkLoadingError1")}<a target="_blank" href="mailto:toefl@mock100.com">
          ${i18n.t("message.chunkLoadingError2")}</a>${i18n.t("message.chunkLoadingError3")}
        `,
        confirmButtonText: i18n.t("alert.refresh"),
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        dangerouslyUseHTMLString: true,
        callback: () => window.location.reload()
      });

      loadingInstance.close();
      return Promise.reject(error);
    }

    if (error.response.status === 400) {
      switch (error.response.data.message) {
        case "Unauthorized":
          Message.error("Incorrect username or password");
          break;
        case "Not found user":
          Message.error("Not found user");
          break;
        default:
          Message.error("Oop, something went wrong. Please contact us.");
      }
    }

    if (error.response.status === 401) {
      JwtService.destroyToken();

      store.dispatch("user/resetToken");
    }

    if (error.response.status === 403) {
      Message.error(error.response.data.message);
    }

    if (error.response.status === 422) {
      let message = "";
      for (let key in error.response.data.errors) {
        message += error.response.data.errors[key];
      }
      Message.error(message);
    }

    loadingInstance.close();
    return Promise.reject(error);
  }
);

export default interceptor;
