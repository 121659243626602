import Vue from "vue";
import axios from "@/common/interceptors.service";
import VueAxios from "vue-axios";
import JwtService from "@/common/jwt.service";
import { Message } from "element-ui";
import i18n from "@/i18n";

const FIRST_TIMEOUT_REMINDER = 20000;
const SERVICE_TIMEOUT_REMINDER = 60000;

const setTimeoutDecorator = apiService => {
  const decorateSetTimeout = func => (
    async (...args) => {
      let isLoading = true;
      const thisUrl = window.location.href;
      let showingMessage = null;
      setTimeout(() => {
        if (isLoading) {
          showingMessage = Message.warning({
            message: i18n.t("message.internetConnect", { thisUrl }),
            dangerouslyUseHTMLString: true,
            showClose: true,
            duration: 0
          });
        }
      }, FIRST_TIMEOUT_REMINDER);
      let result = null;
      try {
        result = await func(...args);
        if (showingMessage) {
          showingMessage.close();
          Message.success({
            message: i18n.t("message.internetConnectionIsRestored"),
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
        }
        isLoading = false;
      } catch (e) {
        isLoading = false;
        throw e;
      }
      return result;
    }
  );

  return {
    ...apiService,
    query: decorateSetTimeout(apiService.query),
    get: decorateSetTimeout(apiService.get),
    post: decorateSetTimeout(apiService.post),
    update: decorateSetTimeout(apiService.update),
    put: decorateSetTimeout(apiService.put),
    patch: decorateSetTimeout(apiService.patch),
    delete: decorateSetTimeout(apiService.delete)
  };
};

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL =  'https://api-toefl.mock100.com/api';
    Vue.axios.defaults.timeout = SERVICE_TIMEOUT_REMINDER;
  },

  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, { params }).catch(error => {
      throw error.response;
    });
  },

  get(resource, slug = "", params, config) {
    return Vue.axios
      .get(`${resource}/${slug}`, { params, ...config })
      .catch(error => {
        throw error.response;
      });
  },

  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config).catch(error => {
      throw error.response;
    });
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      throw error.response;
    });
  },

  put(resource, params, slug = "") {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      throw error.response;
    });
  },

  patch(resource, params, slug = "") {
    return Vue.axios.patch(`${resource}/${slug}`, params).catch(error => {
      throw error.response;
    });
  },

  delete(resource, params, slug = "") {
    return Vue.axios
      .delete(`${resource}/${slug}`, { data: params })
      .catch(error => {
        throw error.response;
      });
  }
};

export default setTimeoutDecorator(ApiService);
